import React from "react"
import {Snackbar} from "@material-ui/core"
import {Alert}from '@material-ui/lab';

const SnackBar=({open,message,type,onClose})=>{
  return (
    <Snackbar
      open={open}
      autoHideDuration={4000}
      onClose={onClose}
    >
      <Alert
        variant="filled"
        onClose={onClose}
        severity={type}
      >{message}</Alert>
    </Snackbar>
  )
}
export default SnackBar;