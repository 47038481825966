import { navigate } from "gatsby";
import PropTypes from "prop-types";
import React,{useState} from "react";
import classNames from 'classnames';
import {Button, AppBar, Toolbar,Hidden, IconButton,Drawer, makeStyles} from "@material-ui/core";
import {Menu} from '@material-ui/icons';

// components
import RightLinks from './RightLinks';
import headerStyle from "../../assets/jss/pale-legs-style/components/headerStyle";

const useStyles = makeStyles(headerStyle);

const Header = (props) => {

    const {changeColorOnScroll, color,brand, fixed,absolute }=props

    const classes = useStyles(props);

    const [mobileOpen,setMobileOpen]=useState(false)

    const headerColorChange=()=>{
        const windowsScrollTop = window.pageYOffset;

        if (windowsScrollTop > changeColorOnScroll) {
            document.body
                .getElementsByTagName("header")[0]
                .classList.remove(classes[color]);
            document.body
                .getElementsByTagName("header")[0]
                .classList.add(classes[changeColorOnScroll.color]);
        } else {
            document.body
                .getElementsByTagName("header")[0]
                .classList.add(classes[color]);
            document.body
                .getElementsByTagName("header")[0]
                .classList.remove(classes[changeColorOnScroll.color]);
        }
    }

    const handleDrawerToggle=()=>setMobileOpen(!mobileOpen);

    /*
    useEffect(()=>{
        window.addEventListener("scroll", headerColorChange)

        return ()=>window.removeEventListener("scroll",headerColorChange)

    },[changeColorOnScroll])

     */

    const appBarClasses = classNames({
        [classes.appBar]: true,
        [classes[color]]: color,
        [classes.absolute]: absolute,
        [classes.fixed]: fixed
    });

    const brandComponent= (<Button onClick={()=>navigate('/')} className={classes.title}>{brand}</Button>)

    return (
          <header>
              <AppBar className={appBarClasses}>
                  <Toolbar className={classes.container}>
                      {brandComponent}
                      <Hidden smDown implementation="css">
                          <RightLinks/>
                      </Hidden>
                      <Hidden mdUp>
                          <IconButton
                              color="inherit"
                              aria-label="open drawer"
                              onClick={handleDrawerToggle}
                          >
                              <Menu />
                          </IconButton>
                      </Hidden>
                  </Toolbar>
                  <Hidden mdUp implementation="js">
                      <Drawer
                          variant="temporary"
                          anchor={"right"}
                          open={mobileOpen}
                          classes={{
                              paper: classes.drawerPaper
                          }}
                          onClose={handleDrawerToggle}
                      >
                          <div className={classes.appResponsive}>
                              <RightLinks/>
                          </div>
                      </Drawer>
                  </Hidden>
              </AppBar>
          </header>
    )
}

Header.propTypes = {
    siteTitle: PropTypes.string,
    color: PropTypes.oneOf([
        "primary",
        "info",
        "success",
        "warning",
        "danger",
        "transparent",
        "white",
        "rose",
        "dark"
    ]),
    rightLinks: PropTypes.node,
    leftLinks: PropTypes.node,
    brand: PropTypes.string,
    fixed: PropTypes.bool,
    absolute: PropTypes.bool,
    // this will cause the sidebar to change the color from
    // this.props.color (see above) to changeColorOnScroll.color
    // when the window.pageYOffset is heigher or equal to
    // changeColorOnScroll.height and then when it is smaller than
    // changeColorOnScroll.height change it back to
    // this.props.color (see above)
    changeColorOnScroll: PropTypes.shape({
        height: PropTypes.number.isRequired,
        color: PropTypes.oneOf([
            "primary",
            "info",
            "success",
            "warning",
            "danger",
            "transparent",
            "white",
            "rose",
            "dark"
        ]).isRequired
    })

}

Header.defaultProps = {
  siteTitle: ``,
  color: "white"
}

export default Header;
