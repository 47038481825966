import React from "react"
import classNames from 'classnames';
import {Dialog,DialogActions,DialogContent,DialogTitle,makeStyles, IconButton, TextField} from "@material-ui/core"
import {Close} from "@material-ui/icons"
import GoogleButton from "react-google-button";
// component
import Transition from "../../Functions/Transition";
import ModalStyle from '../../assets/jss/pale-legs-style/modalStyle';
import Button from "../CustomButtons/Button"
import GridItem from "../Grid/GridItem";
import AuthHooks from "../../Hooks/AuthHooks"
import Danger from "../Typography/Danger"
const useStyles = makeStyles(ModalStyle);




const LoginModal =(props)=>{
    const {show,onClose,title, modalChange, onSubmit, error}=props;
    const classes = useStyles(props);

    const [state,dispatch,Validator]=AuthHooks();

    const {email,password,emailError,passwordError}=state;

    const handleSubmit=()=>{
       const validation = Validator({email,password});

       if(validation){
          onSubmit('login',{email,password});
       }

    }

    const handleGoogleSubmit=()=>{
        onSubmit('google');
    }

    return (
      <Dialog
        //fullWidth
        maxWidth={'sm'}
        classes={{
          root:classes.center,
          paper:classes.modal
        }}
        open={show}
        TransitionComponent={Transition}
        keepMounted
        onClose={onClose}
        aria-labelledby={'modal-slide-title'}
        aria-describedby={'modal-slide-description'}
      >
        <DialogTitle
          id={'classic-modal-slide-title'}
          disableTypography
          className={classes.modalHeader}
        >
          <IconButton
            key={'close'}
            className={classes.modalCloseButton}
            color={'inherit'}
            onClick={onClose}
            aria-label={'close'}
          >
            <Close className={classes.modalClose}/>
          </IconButton>
          <h3>{title}</h3>
        </DialogTitle>
        <DialogContent
          id={'modal-slide-description'}
          className={classes.modalBody}
        >
          <div>
            <GridItem container justify={'center'}>
              <GoogleButton
                onClick={handleGoogleSubmit}
              />
            </GridItem>
            <GridItem container justify={'center'} direction={'column'}>
              <h4 style={{textAlign:'center',marginTop:15}}>Or</h4>
              <h5 style={{textAlign:'center'}}>Login with your credentials below</h5>
              <form onSubmit={handleSubmit}>
                <TextField
                  style={{margin:10}}
                  label={'Email'}
                  id={'email'}
                  helperText={emailError}
                  error={!!emailError}
                  value={email}
                  size={'small'}
                  fullWidth
                  onChange={e=>dispatch({type:'email',payload:e.target.value})}
                />
                <TextField
                  style={{margin:10}}
                  id={'password'}
                  label={'Password'}
                  helperText={passwordError}
                  error={!!passwordError}
                  value={password}
                  fullWidth
                  size={'small'}
                  inputProps={{type:'password'}}
                  onChange={e=>dispatch({type:'password',payload:e.target.value})}
                />
                <GridItem container justify={'space-around'}>
                  <Button onClick={()=>modalChange('forgot')} size={'sm'} simple color={'github'}>Forgot Password?</Button>
                  <Button onClick={()=>modalChange('register')} size={'sm'} simple color={'github'}>Dont have an account?</Button>
                </GridItem>
              </form>
              {error?
                <Danger>{error}</Danger>:null
              }
            </GridItem>
          </div>
        </DialogContent>
        <DialogActions
          className={classNames(classes.modalFooter)}
        >
          <Button
            onClick={onClose}
            size={'sm'} round>
            Cancel
          </Button>
          <Button onClick={handleSubmit} size={'sm'} color={'success'} round>
            Submit
          </Button>

        </DialogActions>
      </Dialog>
    )
}

export default LoginModal;