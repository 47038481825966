import React,{useContext, useReducer} from 'react';
import {Link}from 'gatsby';
import {List, ListItem, Tooltip, Avatar, ListItemAvatar, makeStyles, ListItemText} from "@material-ui/core";
import {FirebaseContext}from '../Firebase';
import headerLinksStyle from "../../assets/jss/pale-legs-style/components/headerLinksStyle";
import LoginModal from "../Modals/LoginModal"
import RegisterModal from "../Modals/RegisterModal"
import ForgotModal from "../Modals/ForgotModal"
import { Loading } from "../Loading"

const useStyles =makeStyles(headerLinksStyle);

const INITIAL_STATE={
  loginModal:false,
  registerModal:false,
  forgotModal:false,
  loading:false,
  regError:null,
  loginError:null,
  forgotError:null,
  forgotSuccess:null
}

const reducer=(state,action)=>{
  switch(action.type){
    case 'resetError':
      return {...state,forgotError: action.payload, forgotSuccess: null,loading:false}
    case 'resetSuccess':
      return {...state,forgotError: null, forgotSuccess:action.payload, loading:false}
    case'loginError':
      return {...state, loading:false,loginError:action.payload}
    case'clearError':
      return {...state,regError: null,loginError: null,forgotError:null}
    case 'regError':
      return {...state,regError:action.payload, loading:false}
    case'loading':
      return {...state,loading:!state.loading}
    case 'changeToRegister':
      return {...state,loginModal: false,forgotModal: false,registerModal: true}
    case 'changeToLogin':
      return {...state,loginModal: true,forgotModal: false,registerModal: false}
    case 'changeToForgot':
      return {...state,loginModal: false,forgotModal: true,registerModal: false}
    case'loginModal':
      return {...state,loginModal:!state.loginModal}
    case 'registerModal':
      return {...state,registerModal:!state.registerModal}
    case 'forgotModal':
      return {...state,forgotModal:!state.forgotModal}
    case 'closeAllModals':
      return {...state,loginModal:false,registerModal:false,forgotModal: false}
    default:
      return {...state}
  }
}


const HeaderLinks=(props)=>{

    const {firebase,user,setUser}=useContext(FirebaseContext);

    const [state,dispatch]=useReducer(reducer,INITIAL_STATE);

    const {loginModal,registerModal,forgotModal, regError, loading,loginError,forgotError,forgetSuccess}=state

    const classes = useStyles(props);

    const handleLogin=(method,data)=>{
        dispatch({type:'loading'});
        dispatch({type:'clearError'})
        if(method==='google'){
          // handle google mode of login
          firebase.googleLogin().then(result=>{
            // run check in db to see if user is there

            dispatch({type:'loading'});
            dispatch({type:'loginModal'});
          }).catch(err=>console.log(err.message));
        }else{
          // login with credentials
          firebase.login(data)
            .then((user)=>{
              dispatch({ type: 'loading' })
              dispatch({type:'loginModal'});

            })
            .catch((err)=>dispatch({type:'loginError',payload:err.message}))

        }
    }

    const handleLogOut=()=>{
        dispatch({type:'loading'});
        firebase.logout().then(()=>dispatch({type:'loading'}))
    }

    const handleRegister=(data)=>{
       dispatch({type:'loading'});
       dispatch({type:'clearError'})
        firebase.registerUser(data)
          .then((newUser)=>{
            setUser(newUser);
            dispatch({type:'loading'});
            dispatch({type:'registerModal'});
          }).catch(err=>dispatch({type:'regError',payload:err.message}))
    }

    const handleForgot=(data)=>{
      dispatch({type:'loading'});
      firebase.resetPassword(data)
        .then(()=>{
          dispatch({type:'resetSuccess',payload:'A link has been sent to your email to reset your password.'})
        }).catch(err=>dispatch({type:'resetError',payload:err.message}))
    }

    const modalChange=(type)=>{
        switch(type){
          case'register':
            return dispatch({type:'changeToRegister'});
          case 'login':
            return dispatch({type:'changeToLogin'});
          case 'forgot':
            return dispatch({type:'changeToForgot'});
        }
    }


    return (
      <div>
        <Loading show={loading}/>
        <RegisterModal
          onClose={()=>dispatch({type:'registerModal'})}
          show={registerModal}
          modalChange={modalChange}
          error={regError}
          onSubmit={handleRegister}
        />
        <LoginModal
            onClose={()=>dispatch({type:'loginModal'})}
            title={'User Login'}
            show={loginModal}
            modalChange={modalChange}
            onSubmit={handleLogin}
            error={loginError}
        />
        <ForgotModal
          show={forgotModal}
          onClose={()=>dispatch({type:'forgotModal'})}
          modalChange={modalChange}
          onSubmit={handleForgot}
          error={forgotError}
          success={forgetSuccess}
        />
        <List className={classes.list}>
          <ListItem className={classes.listItem}>
            <Link to={'/'} className={classes.navLink}>Home</Link>
          </ListItem>
          <ListItem className={classes.listItem}>
            <Link to={'/about'} className={classes.navLink}>About Me</Link>
          </ListItem>
          <ListItem className={classes.listItem}>
            <Link to={'/blog'} className={classes.navLink}>Blog Posts</Link>
          </ListItem>
          {(!!user && !!user.email) ?
            <ListItem className={classes.listItem} onClick={handleLogOut}>
              <div className={classes.avatarList}>
                <Tooltip title={'Sign Out'}>
                  <p className={classes.navLink}>Welcome back {user.displayName || user.email}</p>
                </Tooltip>
                <Avatar className={classes.avatar} alt={'profile'} src={user.photoURL??user.photoURL} />
              </div>

            </ListItem>:
            <ListItem  className={classes.listItem} onClick={()=>dispatch({type:'loginModal'})}>
              <p className={classes.navLink}>Login</p>
            </ListItem>
          }
        </List>
      </div>

    )
}

export default HeaderLinks;