import React from "react"
import {Divider} from "@material-ui/core"

const style={
  borderTop:'2px solid #fff',
  borderRadius:5,
  boxShadow:'0px,1px,1px,-1px,rgba(0,0,0,0.5)',
  marginTop:'1%',
  marginBottom:'1%'
}

const BlogDivider=()=>{
  return (
    <Divider
      style={style}
      variant={'middle'}
    />
  )
}
export default BlogDivider;