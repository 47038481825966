const reg =/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

export default (data)=>{
  let errors={};
  const keys = Object.keys(data);

  keys.forEach(key=>{

    if(!data[key].length>0){
      errors[key]='Please check the your input '+key+'!';
    }

    if(key==='email' && !reg.test(data[key])){
      errors[key]='Please check your email!';
    }

    if(key==='password' && data[key].length<6){
      errors[key]='Password needs to be a minimum of 6 characters!'
    }
  })


  return errors;

}