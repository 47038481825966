import React,{useState} from "react"
import {makeStyles} from "@material-ui/core/styles"
import {Link, graphql, useStaticQuery}from 'gatsby';
import { Facebook, Twitter, Instagram, Email, YouTube, AccountCircleOutlined, MailOutlined } from "@material-ui/icons"
import { InputAdornment, TextField } from "@material-ui/core"

// components
import footerStyle from "../../assets/jss/pale-legs-style/components/footerStyle";
import GridContainer from "../Grid/GridContainer"
import Button from "../CustomButtons/Button"
import BlogDivider from "../Dividers/Divider"
import GridItem from "../Grid/GridItem"
import AuthHooks from "../../Hooks/AuthHooks"
import Card from "../Card/Card"
import CardBody from "../Card/CardBody"
import CardFooter from "../Card/CardFooter"
const useStyles =makeStyles(footerStyle);

const Footer=(props)=>{
  const classes = useStyles(props);

  const {allSiteData} = useStaticQuery(graphql`
      query pageQuery {
          allSiteData(filter: {title: {eq: "socialLinks"}}) {
              edges {
                  node {
                      id
                      title
                      content {
                          contactEmail
                          facebook
                          instagram
                          twitter
                          youtube
                      }
                  }
              }
          }
      }
  `)
  const {facebook,twitter,instagram,youtube,contactEmail}=allSiteData.edges[0].node.content;

  const [state,dispatch,Validator]=AuthHooks();
  const {email, emailError,name,nameError}=state;

  const onSubmit=()=>{
    const validation= Validator({name,email});
    if(validation){
      props.onSignUp({name,email});
    }
  }

  return (
    <footer>
      <BlogDivider/>
      <GridContainer >
        <GridItem xs={12} sm={12} md={6} container justify={'center'} direction={'column'} alignItems={'center'}>
          <h3>Follow Me</h3>
          <ul >
            {facebook!==''?
              <li>
                <a href={facebook}  target="_blank">
                  <Button  round justIcon style={{fontSize:100}}><Facebook /></Button>
                </a>
              </li>
              :''
            }
            {instagram!==''?
              <li>
                <a href={instagram}  target="_blank">
                  <Button round justIcon><Instagram style={{fontSize:40}}/></Button>
                </a>
              </li>:''
            }
            {youtube!==''?
              <li>
                <a href={youtube}  target="_blank">
                  <Button  round justIcon><YouTube style={{fontSize:40}}/></Button>
                </a>
              </li>
              :''
            }
            {twitter!==''?
              <li>
                <a href={twitter}  target="_blank">
                  <Button round justIcon><Twitter style={{fontSize:40}}/></Button>
                </a>
              </li>:''
            }
            {contactEmail!==''?
              <li>
                <a href={'mailto:'+contactEmail} >
                  <Button round justIcon><Email style={{fontSize:40}}/></Button>
                </a>
              </li>:''
            }
          </ul>
        </GridItem>
        <GridItem xs={12} sm={12} md={6} container direction={'column'} alignItems={'center'}>
          <h4>Newsletter Sign Up</h4>
          <p style={{marginTop:-15}}>Sign up below to stay up to receive our latest post updates.</p>
          <div className={classes.SignUp}>
            <TextField
              style={{marginBottom:10}}
              id={'name'}
              label={'Name'}
              helperText={nameError}
              error={!!nameError}
              value={name}
              fullWidth
              size={'small'}
              inputProps={{ type:'text'}}
              onChange={e=>dispatch({type:'name',payload:e.target.value})}
            />
            <TextField
              style={{marginBottom:10}}
              id={'email'}
              label={'Email'}
              helperText={emailError}
              error={!!emailError}
              value={email}
              fullWidth
              size={'small'}
              inputProps={{type:'email'}}
              onChange={e=>dispatch({type:'email',payload:e.target.value})}
            />
            <Button onClick={onSubmit} size={'sm'} block round>Sign Up</Button>
          </div>

        </GridItem>
      </GridContainer>
      <GridContainer justify={'space-between'}>
        <GridItem xs={12} sm={12} md={6}>
          <ul >
            <li>
              <Link className={classes.Link} to={'/'}>Home</Link>
            </li>
            <li>
              <Link className={classes.Link} to={'/about'}>About</Link>
            </li>
            <li>
              <Link className={classes.Link} to={'/blog'}>Blog Posts</Link>
            </li>
            <li>
              <Link className={classes.Link} to={'/disclaimer'}>Disclaimer</Link>
            </li>
          </ul>
        </GridItem>
        <GridItem xs={12} sm={12} md={6}>
          <p style={{textAlign:'right'}}>&copy; {new Date().getFullYear().toString()} My Pale Legs </p>
        </GridItem>

      </GridContainer>
    </footer>
  )
}



export default Footer;