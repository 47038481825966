import React from 'react';
import {
    Dialog, DialogContent, DialogTitle, DialogActions, IconButton, TextField, InputAdornment,
} from '@material-ui/core';
import { Close, MailOutlined, AccountCircleOutlined } from '@material-ui/icons';
import classNames from 'classnames';
import { makeStyles } from '@material-ui/core/styles';

// components
import Transition from '../../Functions/Transition';
import signUpModalStyle from '../../assets/jss/pale-legs-style/signUpModalStyle';
import Button from '../CustomButtons/Button';
import Danger from '../Typography/Danger';
import AuthHooks from '../../Hooks/AuthHooks';
import GridContainer from '../Grid/GridContainer';
import GridItem from '../Grid/GridItem';

const useStyles = makeStyles(signUpModalStyle);

const NewsLetterSignUp = ({
    show, onClose, onSignUp, error,
}) => {
    const classes = useStyles();

    const [state, dispatch, Validator] = AuthHooks();

    const {
        email, emailError, name, nameError,
    } = state;

    const onSubmit = () => {
        const validation = Validator({ name, email });
        if (validation) {
            onSignUp({ name, email });
        }
    };

    return (
        <Dialog
            fullWidth
            maxWidth="sm"
            classes={{
                root: classes.center,
                paper: classes.modal,
            }}
            open={show}
            TransitionComponent={Transition}
            keepMounted
            onClose={onClose}
            aria-labelledby="modal-slide-title"
            aria-describedby="modal-slide-description"
        >
            <DialogTitle
                id="classic-modal-slide-title"
                disableTypography
                className={classes.modalHeader}
            >
                <IconButton
                    key="close"
                    className={classes.modalCloseButton}
                    color="inherit"
                    onClick={onClose}
                    aria-label="close"
                >
                    <Close className={classes.modalClose} />
                </IconButton>
                <h3 className={classes.modalTitle}><strong>Sign up now!</strong></h3>
            </DialogTitle>
            <DialogContent
                id="modal-slide-description"
                className={classes.modalBody}
            >
                <div>
                    <p>Sign up below to stay updated with my latest posts, and you'll be sent a free exclusive recipe. :)</p>
                    <GridContainer justify="center" direction="column">
                        <GridContainer>
                            <GridItem xs={12} sm={12} md={6}>
                                <TextField
                                    style={{ margin: 10 }}
                                    id="name"
                                    label="Name"
                                    helperText={nameError}
                                    error={!!nameError}
                                    value={name}
                                    fullWidth
                                    size="small"
                                    inputProps={{ type: 'text' }}
                                    InputProps={{
                                        endAdornment: <InputAdornment position="end"><AccountCircleOutlined color="disabled" /></InputAdornment>,
                                    }}
                                    onChange={(e) => dispatch({ type: 'name', payload: e.target.value })}
                                />
                            </GridItem>
                            <GridItem xs={12} sm={12} md={6}>
                                <TextField
                                    style={{ margin: 10 }}
                                    id="email"
                                    label="Email"
                                    helperText={emailError}
                                    error={!!emailError}
                                    value={email}
                                    fullWidth
                                    size="small"
                                    inputProps={{ type: 'email' }}
                                    InputProps={{
                                        endAdornment: <InputAdornment position="end"><MailOutlined color="disabled" /></InputAdornment>,
                                    }}
                                    onChange={(e) => dispatch({ type: 'email', payload: e.target.value })}
                                />
                            </GridItem>
                        </GridContainer>
                        <Button onClick={onClose} simple size="sm" color="github">Maybe another time</Button>
                        <Danger>{error}</Danger>
                    </GridContainer>
                </div>
            </DialogContent>
            <DialogActions
                className={classNames(classes.modalFooter)}
            >

                <Button onClick={onSubmit} size="sm" color="success" round>
                    Sign Up
                </Button>
            </DialogActions>
        </Dialog>
    );
};
export default NewsLetterSignUp;
