import React from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, IconButton, makeStyles, TextField } from "@material-ui/core"
import { Close } from "@material-ui/icons"
import classNames from "classnames"

// components
import Transition from "../../Functions/Transition"
import GridItem from "../Grid/GridItem"
import CustomInput from "../CustomInput/CustomInput"
import Button from "../CustomButtons/Button"
import modalStyle from "../../assets/jss/pale-legs-style/modalStyle"
import AuthHooks from "../../Hooks/AuthHooks"
import Danger from "../Typography/Danger"
const useStyles = makeStyles(modalStyle)


const RegisterModal=(props)=>{

  const classes = useStyles(props);
  const {show,onClose,modalChange,error,onSubmit}=props;

  const [state,dispatch, Validator]=AuthHooks();

  const {email,password,name,emailError,passwordError,nameError}=state;

  const handleSubmit=()=>{
    const validation =Validator({email,name,password})
    if(validation){
        onSubmit({email,password,name});
    }
  }

  return (
    <Dialog
      //fullWidth
      maxWidth={'sm'}
      classes={{
        root:classes.center,
        paper:classes.modal
      }}
      open={show}
      TransitionComponent={Transition}
      keepMounted
      onClose={onClose}
      aria-labelledby={'modal-slide-title'}
      aria-describedby={'modal-slide-description'}
    >
      <DialogTitle
        id={'classic-modal-slide-title'}
        disableTypography
        className={classes.modalHeader}
      >
        <IconButton
          key={'close'}
          className={classes.modalCloseButton}
          color={'inherit'}
          onClick={onClose}
          aria-label={'close'}
        >
          <Close className={classes.modalClose}/>
        </IconButton>
        <h3>Register User</h3>
      </DialogTitle>
      <DialogContent
        id={'modal-slide-description'}
        className={classes.modalBody}
      >
        <div>

          <GridItem container justify={'center'} direction={'column'}>
            <h5 style={{textAlign:'center'}}>Please register with your details below</h5>
              <TextField
                style={{margin:10}}
                id={'name'}
                label={'Name'}
                helperText={nameError}
                error={!!nameError}
                value={name}
                fullWidth
                size={'small'}
                inputProps={{type:'text'}}
                onChange={e=>dispatch({type:'name',payload:e.target.value})}
              />
              <TextField
                style={{margin:10}}
                id={'email'}
                label={'Email'}
                helperText={emailError}
                error={!!emailError}
                value={email}
                fullWidth
                size={'small'}
                inputProps={{type:'email'}}
                onChange={e=>dispatch({type:'email',payload:e.target.value})}
              />
              <TextField
                style={{margin:10}}
                id={'password'}
                label={'Password'}
                helperText={passwordError}
                error={!!passwordError}
                value={password}
                fullWidth
                size={'small'}
                inputProps={{type:'password'}}
                onChange={e=>dispatch({type:'password',payload:e.target.value})}
              />
            <Button onClick={()=>modalChange('login')} size={'sm'} simple color={'github'}>Already have an account?</Button>
            {error??
              <Danger>{error}</Danger>
            }
          </GridItem>
        </div>
      </DialogContent>
      <DialogActions
        className={classNames(classes.modalFooter)}
      >
        <Button
          onClick={onClose}
          size={'sm'} round>
          Cancel
        </Button>
        <Button onClick={handleSubmit} size={'sm'} color={'success'} round>
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default RegisterModal;