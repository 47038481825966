import React from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, IconButton, TextField } from "@material-ui/core"
import {makeStyles} from "@material-ui/core/styles"
import { Close } from "@material-ui/icons"
import classNames from "classnames"

// components
import AuthHooks from "../../Hooks/AuthHooks"
import modalStyle from "../../assets/jss/pale-legs-style/modalStyle"
import Transition from "../../Functions/Transition"
import GridItem from "../Grid/GridItem"
import Button from "../CustomButtons/Button"
import Danger from "../Typography/Danger"
import Success from "../Typography/Success"
const useStyles = makeStyles(modalStyle);

const ForgotModal=(props)=>{
  const classes = useStyles(props);
  const {show,modalChange,onClose, onSubmit, error,success}=props;

  const [state,dispatch,Validator]=AuthHooks();
  const {email,emailError}=state;

  const handleSubmit=()=>{
    const validation = Validator({email});

    if(validation){
      onSubmit({email});
    }
  }



  return (
    <Dialog
      //fullWidth
      maxWidth={'sm'}
      classes={{
        root:classes.center,
        paper:classes.modal
      }}
      open={show}
      TransitionComponent={Transition}
      keepMounted
      onClose={onClose}
      aria-labelledby={'modal-slide-title'}
      aria-describedby={'modal-slide-description'}
    >
      <DialogTitle
        id={'classic-modal-slide-title'}
        disableTypography
        className={classes.modalHeader}
      >
        <IconButton
          key={'close'}
          className={classes.modalCloseButton}
          color={'inherit'}
          onClick={onClose}
          aria-label={'close'}
        >
          <Close className={classes.modalClose}/>
        </IconButton>
        <h3>Forgot Password</h3>
      </DialogTitle>
      <DialogContent
        id={'modal-slide-description'}
        className={classes.modalBody}
      >
        <div>
          <GridItem container justify={'center'} direction={'column'}>
            <h5 style={{textAlign:'center'}}>Forgot your password?</h5>
            <h6>No problem, enter your email below and well send you a link to reset it.</h6>
            <TextField
              style={{margin:10}}
              label={'Email'}
              id={'email'}
              helperText={emailError}
              error={!!emailError}
              value={email}
              size={'small'}
              fullWidth
              onChange={e=>dispatch({type:'email',payload:e.target.value})}
            />
            <GridItem container justify={'space-around'}>
              <Button onClick={()=>modalChange('login')} size={'sm'} simple color={'github'}>Login Rather?</Button>
            </GridItem>
          </GridItem>
          {error?
            <Danger>{error}</Danger>:null
          }
          {success?
            <Success>{success}</Success>:null
          }
        </div>
      </DialogContent>
      <DialogActions
        className={classNames(classes.modalFooter)}
      >
        <Button
          onClick={onClose}
          size={'sm'} round>
          Cancel
        </Button>
        <Button onClick={handleSubmit} size={'sm'} color={'success'} round>
          Submit
        </Button>

      </DialogActions>
    </Dialog>
  )


}

export default ForgotModal;