import {useReducer}from 'react';
import Validation from "../Functions/Validation"


const reducer=(state,action)=>{

  const {type,payload}=action;

  switch(type){
    case 'nameError':
      return {...state,nameError:payload}
    case 'emailError':
      return {...state,emailError:payload}
    case 'passwordError':
      return {...state,passwordError:payload}
    case 'name':
      return {...state,name:payload, nameError: null}
    case'email':
      return {...state,email:payload, emailError: null}
    case 'password':
      return {...state,password:payload, passwordError: null};
    default:
      return {...state};
  }
}

const INITIAL_STATE={
  email:'',
  emailError:null,
  password:'',
  passwordError:null,
  name:'',
  nameError:null
}


export default ()=>{
    const [state,dispatch]=useReducer(reducer,INITIAL_STATE);

    const Validator=(data)=>{
        const check =Validation(data);

        if(Object.keys(check).length>0){

          for(let key in check){
            if(check[key]){
              dispatch({type:`${key}Error`,payload:check[key]})
            }
          }

          return false
        }else{
          return true
        }
    }



  return [state,dispatch,Validator];

}