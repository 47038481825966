import firebaseConfig from "./config";

class Firebase {
  constructor(app) {
    if(!firebaseInstance) {
      app.initializeApp(firebaseConfig);
      this.auth = app.auth();
      this.db = app.database();
      this.functions = app.functions();
      this.storage = app.storage();
      this.auth_=app.auth;
    }

    if(process.env.NODE_ENV==='development'){
      this.functions.useFunctionsEmulator('http://localhost:5000')
    }


  }

  async login({email, password}) {
     await this.auth.signInWithEmailAndPassword(email, password);
  }

  async logout() {
    await this.auth.signOut();
  }

  async googleLogin(){

    let provider = new this.auth_.GoogleAuthProvider();

   return await this.auth.signInWithPopup(provider);
  }

  async resetPassword({email}){
    await this.auth.sendPasswordResetEmail(email);
  }

  async registerUser({email,password,name}){
     await this.auth.createUserWithEmailAndPassword(email,password);

     const user =await this.auth.currentUser;

    await user.updateProfile({
       displayName:name
     })

    //console.log(user);
    return user;
  };

  async SubscribeToComments({postId,callback,error}){
     await this.db.ref(`/comments/${postId}`).on('value',callback,error);
  }

  async UnSubScribeToComments(postId){
    await this.db.ref(`/comments/${postId}`).off();
  }

  async postComment({author,text,postId,authorId,avatar}){
      await this.db.ref(`/comments/${postId}`).push({
        author,
        authorId,
        text,
        avatar,
        date:Date.now(),
        read:false,
        hidden:false
      })
  }

  async SignUpToNewsletter({name,email}){

    const signUpCallable=this.functions.httpsCallable('EmailSignUp');
    return signUpCallable({email,name});

  }

}



let firebaseInstance;

function getFirebaseInstance(app) {
  if(!firebaseInstance && app){
    firebaseInstance = new Firebase(app);
    return firebaseInstance;
  }else if(firebaseInstance){
    return firebaseInstance
  }else{
    return null;
  }
}

export default getFirebaseInstance;
