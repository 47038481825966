import React, { useEffect, useReducer } from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';
import CookieConsent from 'react-cookie-consent';
import Cookies from 'js-cookie';

// components
import Header from '../Header/header';
import { FirebaseContext, useAuth } from '../Firebase';
import '../../assets/material-dashboard-react.css';
import Footer from '../Footer/Footer';
import NewsLetterSignUp from '../Modals/NewsLetterSignUp';
import { Loading } from '../Loading';
import SnackBar from '../SnackBar/SnackBar';

const INITIAL_STATE = {
    signUpModal: false,
    Load: false,
    error: null,
    success: null,
    notif: false,
    notifMsg: '',
    notifType: 'warning',
};

const reducer = (state, action) => {
    const { type, payload } = action;

    switch (type) {
    case 'closeNotif':
        return {
            ...state, notif: false, notifMsg: '', notifType: 'warning',
        };
    case 'startSignUp':
        return {
            ...state, Load: true, error: null, success: null,
        };
    case 'signUpModal':
        return { ...state, signUpModal: !state.signUpModal };
    case 'setSuccess':
        return {
            ...state,
            error: '',
            success: payload,
            Load: false,
            signUpModal: false,
            notif: true,
            notifMsg: payload,
            notifType: 'success',
        };
    case 'clearError':
        return { ...state, error: '' };
    case 'setError':
        return { ...state, Load: false, error: payload };
    case 'loading':
        return { ...state, Load: !state.Load };
    default:
        return { ...state };
    }
};

const Layout = ({ children }) => {
    const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

    const [state, dispatch] = useReducer(reducer, INITIAL_STATE);

    const { user, loading, firebase } = useAuth();

    const handleEmailSignUp = ({ email, name }) => {
        dispatch({ type: 'startSignUp' });
        // send request to server
        firebase.SignUpToNewsletter({ name, email })
            .then(() => {
                // if successful set cookie
                Cookies.set('my-pale-legs-sign-up', 'true', { expires: 30 });
                // then close modal & notify
                dispatch({ type: 'setSuccess', payload: 'Successfully signed up.' });
            })
            .catch((err) => dispatch({ type: 'setError', payload: err.message }));

    // if unsuccessful
        // set error
    };

    // useEffect(() => {
    //     if (!Cookies.get('my-pale-legs-sign-up')) {
    //     // no cookie, dispatch sign up modal
    //         dispatch({ type: 'signUpModal' });
    //     } else {
    //     // reset cookie
    //         Cookies.set('my-pale-legs-sign-up', true, { expires: 30 });
    //     }
    // }, []);

    const {
        signUpModal, error, Load, notif, notifType, notifMsg,
    } = state;

    return (
        <FirebaseContext.Provider value={{ user, loading, firebase }}>
            <Loading show={Load} />
            <SnackBar
                type={notifType}
                open={notif}
                message={notifMsg}
                onClose={() => dispatch({ type: 'closeNotif' })}
            />
            {/* <NewsLetterSignUp
          show={signUpModal}
          onClose={()=>dispatch({type:'signUpModal'})}
          error={error}
          onSignUp={handleEmailSignUp}
        /> */}
            <CookieConsent
                location="bottom"
                buttonText="Accept"
                cookieName="pale-legs-cookie-accept"
                style={{ background: '#2B373B' }}
                buttonStyle={{ color: '#4e503b', fontSize: '13px', backgroundColor: '#efe' }}
                expires={150}
            >
                We use cookies and other technologies to tailor your experience. Learn more by reading our Disclaimer. By clicking "Accept" or by continuing to use the site, you agree to the use of cookies and data.
            </CookieConsent>
            <Header
                fixed
                color="white"
                brand={data.site.siteMetadata.title}
            />
            <div>
                {children}
            </div>
            <Footer
                onSignUp={handleEmailSignUp}
            />
        </FirebaseContext.Provider>
    );
};

Layout.propTypes = {
    children: PropTypes.node.isRequired,
};

export default Layout;
