import { useEffect, useState } from "react"
import getFirebaseInstance from "./firebase"
import loadFirebaseDependencies from "./loadFirebaseDependencies"

function useAuth() {
    const [user, setUser] = useState(null)
    const [firebase, setFirebase] = useState(null)
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        let unsubscribe

        loadFirebaseDependencies.then(app => {
            const firebaseInstance = getFirebaseInstance(app)

            setFirebase(firebaseInstance)

            unsubscribe = firebaseInstance.auth.onAuthStateChanged(userResult=>{
                if(userResult){
                    const uid = userResult.uid;
                     firebaseInstance.db.ref(`/users/${uid}`).once('value',snap=>{
                        const values=snap.val();
                        if(values){
                            setUser({...userResult,displayName:values.displayName})
                        }else{
                            setUser( {...userResult});
                        }
                    })

                }else{
                    setUser(null);
                }
                setLoading(false);
            });

            /*

            unsubscribe = firebaseInstance.auth.onAuthStateChanged(userResult => {
                if (userResult) {
                    setUser({...userResult});
                }else{
                    setUser(null);
                }
                setLoading(false);
            })

             */
        })

        return () => {
            if (unsubscribe) {
                unsubscribe()
            }
        }
    }, [])


    return { user, firebase, loading,setUser }
}

export default useAuth
